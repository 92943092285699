import React from "react"
import { graphql } from "gatsby"

// import app components
import { FlexibleContent } from "components"

export default function DefaultPageTemplate(props) {
  const {
    data: {
      page: {
        title,
        uri,
        acf: { modules }
      }
    }
  } = props

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri
          }}
        />
      )}
    </>
  )
}

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acf {
        modules {
          ... on WpPage_Acf_Modules_Accordion {
            __typename
            items {
              title
              text
            }
          }
          ... on WpPage_Acf_Modules_Features {
            __typename
            headline
            items {
              headline
              text
              button {
                url
                title
                target
              }
              imageSize
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
            text
          }
          ... on WpPage_Acf_Modules_Boats {
            __typename
            category {
              databaseId
            }
          }
          ... on WpPage_Acf_Modules_Newsletter {
            __typename
            headline
            formId
          }
          ... on WpPage_Acf_Modules_Carousel {
            __typename
            headline
            items {
              button {
                target
                title
                url
              }
              headline
              description
              highlight
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Gallery {
            __typename
            images {
              youtubeUrl
              caption
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Headline {
            __typename
            headline
          }
          ... on WpPage_Acf_Modules_Hero {
            __typename
            mediaVariant
            videoUpload {
              localFile {
                publicURL
              }
              mediaItemUrl
            }
            contentAlignment
            description
            button {
              target
              title
              url
            }
            topLabel
            headline
            headlineStyle
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            video
            height
          }
          ... on WpPage_Acf_Modules_ImageLinks {
            __typename
            alignment
            layout
            images {
              fontStyle
              mediaVariant
              video
              videoUpload {
                localFile {
                  publicURL
                }
                mediaItemUrl
              }
              topLabel
              headline
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPage_Acf_Modules_Text {
            __typename
            text
          }
          ... on WpPage_Acf_Modules_TextImage {
            __typename
            headline
            headlineStyle
            button {
              target
              title
              url
            }
            backgroundColor
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            imageAlignment
            imageSize
            imageVerticalAlignment
            text
          }
          ... on WpPage_Acf_Modules_Testimonials {
            __typename
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            items {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: CONSTRAINED)
                  }
                }
              }
              name
              text
            }
          }
          ... on WpPage_Acf_Modules_Form {
            __typename
            title
            formId
            getParameter
            newFormId
          }
          ... on WpPage_Acf_Modules_Downloads {
            __typename
            items {
              title
              file {
                sourceUrl
              }
            }
          }
          ... on WpPage_Acf_Modules_News {
            __typename
          }
          ... on WpPage_Acf_Modules_SocialFeed {
            __typename
          }
          ... on WpPage_Acf_Modules_DealerLocator {
            __typename
          }
          ... on WpPage_Acf_Modules_Events {
            __typename
          }
        }
      }
    }
  }
`
